/* InfoFormPage.css */

.login-container-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 44px);
  background-color: #f5f5f5;
}
.required-field::after {
  content: '*';
  color: red;
}

.login-box-info {
  width: 30%;
  height: 80%;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  /* text-align: center; */
  overflow-y: auto;
}

.login-heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333333;
}

.login-button {
  width: 100%;
  height: 40px;
  padding: 10px;
  background-color: #4285f4;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #3367d6;
}

.login-button:disabled {
  background: #555;
  cursor: not-allowed;
}

.back-button {
  width: 100%;
  height: 40px;
  padding: 10px;
  color: black;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 8px;
}

.login-footer {
  margin-top: 20px;
  font-size: 14px;
  color: #666666;
}

.login-footer a {
  color: #4285f4;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}

.form-element div {
  display: flex;
}

.form-element > div > label {
  width: 55%;
  display: flex;
  align-self: center;
}

.form-input {
  padding: 10px;
  margin: 10px 0px;
  width: 50%;
  border: 1px solid #ccc;
}

.form-notes {
  padding: 10px;
  margin: 10px 0px;
  width: 80%;
  height: 200px; /* Added height */
  border: 1px solid #ccc;
  font-size: 16px; /* Increased font size */
  line-height: 1.5; /* Added line height */
  resize: vertical;
}

.char-count {
  font-size: 12px; /* Adjust the font size as needed */
  color: #666; /* Adjust the color as needed */
  margin-top: 5px; /* Adjust the spacing as needed */
}

.form-element .radio-group {
  margin: 10px;
}

.address {
  position: relative;
}

.address-list {
  width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.address-list ul {
  width: 100%;
  list-style: none;
  max-height: 100px;
  margin: 0;
  overflow: auto;
  padding: 0;
  text-indent: 10px;
  width: 200px;
  z-index: 1;
  background-color: white;
  position: absolute;
}

.address-list li {
  padding: 2px;
  line-height: 25px;
  display: inline-block;
  display: inline;
  cursor: pointer;
}

.address-list li:hover {
  background-color: #f0f0f0;
  width: 100%;
}

/* Styles for screens with a maximum width of 768px (typical for tablets) */
@media (max-width: 768px) {
  .login-box-info {
    width: 85%;
    padding: 30px;
  }
}
/* Styles for screens with a maximum width of 576px (typical for smartphones) */
@media (max-width: 576px) {
  .login-box-info {
    width: 100%;
    padding: 20px;
  }
}

/* Styles for screens with a maximum width of 320px (typical for smaller smartphones) */
@media (max-width: 320px) {
  .login-box-info {
    width: 100%;
    padding: 20px;
  }
}
