.login-form-container {
    align-items: center;
    justify-content: center;
    overflow: auto;
    display: flex;
    background-color: #f5f5f5;
}

.required-field::after {
    content: " *";
    color: red;
}

.form-data-address {
    padding: 49px;
    border: 1px solid black;
    background-color: white;
}

.form-data-container {
    display: flex;
    margin-bottom: 16px;
}

.form-data-label {
    width: 50%;
    font-size: 13px;
}

.form-data-value {
    width: 50%;
    font-size: 12px;
}

.phone-number {
    margin-right: 15px;
    font-size: 12px;
}

.phone-number-input {
    width: 90%;
    font-size: 12px;
    height: 25px;
    border: 1px solid #aaa;
}

.form-submit {
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 39px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 12px;
}

.form-submit:hover {
    background-color: #3367d6;
}

.form-submit:disabled {
    background: #55555578;
    cursor: not-allowed;
}

.email-input {
    width: 90%;
    height: 25px;
    border: 1px solid #aaa;
}

.label-pet-children-present {
    font-size: 13px;
    width: 50%;
}

.address-input {
    width: 90%;
    height: 25px;
    border: 1px solid #aaa;
}

.chat-form-address-list {
    width: 90%;
    bottom: 0;
    right: 0;
    position: relative;
}
.chat-form-address-list ul{
  width: 100%;
  list-style:none;
  max-height:100px;
  margin:0;
  overflow: auto;
  padding:0;
  text-indent:10px;
  z-index: 1;
  background-color: white;
  position: absolute;
}

.chat-form-address-list  li{
  padding: 2px;
  line-height:25px;
  *display: inline;
  cursor: pointer;
}

.chat-form-address-list  li:hover {
  background-color: #f0f0f0;
  width: 100%;
}

.warning_unit_occupied{
    text-align: center;
    color: red;
    font-size: 15px;
    margin-top: 10px;
}
.neighborhood-select {
    height: 25px;
    border: 1px solid #aaa;
    width: 93%;
    background-color: white;
}
.leaseholder-select {
    height: 25px;
    border: 1px solid #aaa;
    width: 93%;
    background-color: white;
}