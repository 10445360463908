/* CSS for the popup */
@import url('https://cdn.syncfusion.com/ej2/material.css');
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup p {
  margin-bottom: 10px;
}

.popup button {
  margin-right: 10px;
}
.proceed-button {
  width: 80px;
  height: 30px;
  margin-top: 20px;
  border: none;
  background-color: #4caf50;
  border-radius: 18px;
  color: #fff;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.cancel-button {
  width: 80px;
  height: 30px;
  margin-top: 20px;
  border: none;
  background-color: gray;
  border-radius: 18px;
  color: white;
}

/* CSS for the refresh button */
.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
}

.chat-header label {
  font-size: 18px;
  font-weight: bold;
}

.form-icon,
.refresh-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Additional style for the refresh button */
.refresh-button {
  /* background-color: #4caf50; */
  /* border-radius: 50%; */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 20px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  margin-left: 8px;
  cursor: pointer;
}

/* .refresh-button:hover {
  background-color: #45a049;
} */

.submit-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  /* z-index: 9999; */
}

.submit-popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40%;
}

.start-new-chat {
  width: 120px;
  height: 35px;
  color: white;
  background-color: #3bd693;
  border: none;
  border-radius: 15px;
}

.end-chat {
  height: 35px;

  width: 120px;
  color: black;
  background-color: white;
  border: none;
  border-radius: 15px;

  border: 1px solid #d5d5d5;
}

.created {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.popup-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

.progress-bar {
  width: 75%;
  padding-bottom: 1%;
}
.progress-bar div {
  height: 23px !important;
}
.progress-bar div div {
  height: 23px !important;
  background: #83c442 !important;
  /* background: repeating-linear-gradient( 45deg, #1836f1, #0a2beb 5px, #0f33d7 4px, #1830d7 10px )!important; */
}
.progress-bar-label {
  padding-left: 15px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-style: italic !important;
  color: 'red' !important;
}

.view-summary-button {
  color: #465635;
  text-decoration: underline;
  cursor: pointer;
}

.view-summary-button:hover {
  color: #3A4A2C;
}

.view-summary-button:visited {
  color: #3A4A2C;
}
