body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Helvetica' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 600px;
  overflow: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.space-20 {
  height: 20px;
  display: block;
}
.chat-bot-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 44px);
  background-color: #f0f0f0;
  flex-direction: column;
}

.chat-window {
  width: 75%;
  height: 85%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.chat-window .chat-header {
  background: #52663c;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.chat-window .chat-header * {
  color: white;
}

.chat-display {
  padding: 10px;
  height: 100%;
  /* height: calc(100% - 50%); */
}

.chat-text-area {
  overflow-y: auto;
  height: calc(100% - 70px);
  flex: 1;
}

.chat-options {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 40px;
}

.chat-options label {
  padding: 7px 10px;
  border: 1px solid #ccc;
  margin: 2px;
  border-radius: 8px;
  cursor: pointer;
}

.Duplicate-wo-bubble {
  background-color: #FFBFDA;
}

.Repeat-wo-bubble {
  background-color: #C6B7FF;
}
.Reopen-wo-bubble {
  background-color: #CEE2FF;
}

.chat-text {
  display: flex;
  justify-content: start;
}

.chat-text.right {
  justify-content: end;
}


.chat-text.left label {
  background: #cdd9bd;
  color: #3a462e;
}

.chat-text label {
  padding: 7px 10px;
  background: #e5ebdc;
  color: #3a462e;
  border-radius: 8px;
  display: inline-block;
  margin: 1px;
}
.waiting-message {
  background-color: grey !important;
  font-size: 12px;
  color: white !important;
}
.wrapped-pre {
  white-space: pre-wrap;
}

.input-form {
  min-height: 50px;
  display: flex;
  background-color: #d9d9d9;
  border-radius: 10px;
}

.MuiFormControl-fullWidth {
  width: 97% !important;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 6px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.input-form input[type='text'] {
  padding: 5px 10px;
  border: none;
  flex: 1;
  border-radius: 10px;
  background-color: #d9d9d9;
  width: 100%;
  font-size: 14px !important;
}

.input-form input[type='text']:focus {
  outline: none;
}

.input-form input[type='submit'] {
  border: none;
  border-radius: 10px;
  height: 37px;
  width: 71px;
  position: relative;
  top: 6px;
  color: #d9d9d9;
  right: 11px;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}

.input-form span {
  border: none !important;
  margin-left: 10px;
  margin-right: 10px;
}
.input-form .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: none !important;
}

.form-icon-container {
  margin-left: auto;
  cursor: pointer;
}

.form-icon {
  width: 20px;
  height: 20px;
}

.message-txt {
  width: 100%;
  text-align: center;
}
.submit-button {
  background-color: #465635;
  color: white !important;
}
.submit-input {
  cursor: default;
}

.submit-input:hover:not(:disabled) {
  cursor: pointer;
}
/* Styles for screens with a maximum width of 768px (typical for tablets) */
@media (max-width: 768px) {
  .chat-window {
    width: 80%;
  }
}
/* Styles for screens with a maximum width of 576px (typical for smartphones) */
@media (max-width: 576px) {
  .chat-window {
    width: 100%;
  }
}

/* Styles for screens with a maximum width of 320px (typical for smaller smartphones) */
@media (max-width: 320px) {
  .chat-window {
    width: 100%;
  }
}
