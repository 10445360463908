/* LoginPage.css */

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-box {
  width: 400px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333333;
}


.login-footer {
  margin-top: 20px;
  font-size: 14px;
  color: #666666;
}

.login-footer a {
  color: #4285f4;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}
.logout-button-container {
  height: 44px;
  background-color: #f5f5f5;
}
.logout-button {
  position: relative;
  padding: 8px 16px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 17px;
  margin-top: 8px;
  font-family: 'Helvetica' !important;
}

.logout-button:hover {
  background-color: #d32f2f;
}

/* Styles for screens with a maximum width of 768px (typical for tablets) */
@media (max-width: 768px) {
  .login-box {
    width: 400px;
  }
}
/* Styles for screens with a maximum width of 576px (typical for smartphones) */
@media (max-width: 576px) {
  .login-box {
    width: 100%;
  }
  .login-heading {
    font-size: 23px !important;
  }
  .login-button {
    font-size: 13px !important;
  }
}

/* Styles for screens with a maximum width of 320px (typical for smaller smartphones) */
@media (max-width: 320px) {
  .login-box {
    width: 100%;
  }
  .login-heading {
    font-size: 23px !important;
  }
  .login-button {
    font-size: 13px !important;
  }
}
